<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: page_route,
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${data ? `${data.folio_full} | ` : ''}${$route.meta.title}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              page_route == 'caja_medicos' &&
              data &&
              login.permissions.rs_services_insured_bills.update &&
              !data.rs_service_insured_bill
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="info"
                class="ml-1"
                v-on="on"
                x-small
                dark
                fab
                :to="{
                  name: `${page_route}.editar`,
                  params: { id: data.rs_id },
                }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="data">
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `SERVICIO | ${data.folio_full}${
                      data.rs_service_no_payment_type
                        ? ` (${data.rs_service_no_payment_type.no_payment_types})`
                        : ''
                    }`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip
                  v-if="
                    page_route == 'caja_medicos' &&
                    !data.rs_service_insured_bill
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        data.rs_service_no_payment_type ? 'error' : 'gray'
                      "
                      class="ml-1"
                      icon
                      @click.prevent="noPaymentDialog"
                    >
                      <v-icon> mdi-file-document-remove </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Motivo de no pago'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="F. creación" :value="data.created_at" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Asegurado"
                    :value="data.rs.insured_full_name"
                  />
                </v-col>

                <v-col v-if="data.doctor" cols="12" xs="12" md="4">
                  <ViewData
                    label="Médico"
                    :value="
                      'IDM ' + data.doctor.id + ' | ' + data.doctor.full_name
                    "
                  />
                </v-col>
                <v-col v-if="data.specialty_type" cols="12" xs="12" md="4">
                  <ViewData
                    label="Especialidad"
                    :value="data.specialty_type.specialty_type"
                  />
                </v-col>
                <v-col
                  v-if="data.consulting_room && data.consulting_room.provider"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Consult. | Hosp."
                    :value="data.consulting_room.provider.trade_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Monto"
                    :value="numberFormat(data.benefit_amount)"
                  />
                </v-col>
                <v-col v-if="data.rs.rs_documents" cols="12">
                  <v-row dense>
                    <v-col
                      cols="12"
                      v-for="(rs_document, i) in data.rs.rs_documents"
                      :key="i"
                    >
                      <v-row>
                        <v-col cols="12">
                          <span
                            class="text-caption font-weight-bold"
                            v-text="`Documento (${i + 1})`"
                          />
                          <br />
                          <span
                            class="text-description"
                            v-text="rs_document.description"
                          />

                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                :href="
                                  url_documents +
                                  '/rs_documents/' +
                                  rs_document.url
                                "
                                target="_blank"
                              >
                                <v-icon small>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver'" />
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="page_route == 'caja_medicos' && data.rs_service_insured_bill"
          cols="12"
        >
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    `COBRO | ${data.rs_service_insured_bill.folio} | ${
                      !data.rs_service_insured_bill.stamp_id ? 'SIN' : 'CON'
                    } FACTURA`
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip left v-if="data.rs_service_insured_bill.stamp_id">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="cyan darken-3"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          data.rs_service_insured_bill.stamp_id,
                          'pdf'
                        )
                      "
                    >
                      <v-icon> mdi-file-download </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar PDF'" />
                </v-tooltip>
                <v-tooltip left v-if="data.rs_service_insured_bill.stamp_id">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal darken-2"
                      class="ml-1"
                      icon
                      @click.prevent="
                        downloadFile(
                          data.rs_service_insured_bill.stamp_id,
                          'xml'
                        )
                      "
                    >
                      <v-icon> mdi-xml </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar XML'" />
                </v-tooltip>
                <v-tooltip left v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal darken-2"
                      class="ml-1"
                      icon
                      :href="
                        url_pdf +
                        '/comprobante/pago/' +
                        data.rs_service_insured_bill.id
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-file-certificate </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar Comprobante'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="orange darken-3"
                      class="ml-1"
                      icon
                      :disabled="!data.rs_service_insured_bill.url_ticket"
                      :href="
                        url_documents +
                        '/rs_service_insured_bills/' +
                        data.rs_service_insured_bill.url_ticket
                      "
                      target="_blank"
                    >
                      <v-icon> mdi-receipt-text </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Recibo de pago'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Paciente | C. electrónico"
                    :value="data.rs.email"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Monto*"
                    :value="
                      numberFormat(data.rs_service_insured_bill.pay_amount)
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Total cobrado"
                    :value="
                      numberFormat(
                        data.rs_service_insured_bill.insurance_pay_amount
                      )
                    "
                  />
                </v-col>
              </v-row>
              <v-row v-if="data.rs_service_insured_bill.stamp_id" dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Razón social"
                    :value="data.rs_service_insured_bill.legal_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="RFC"
                    :value="data.rs_service_insured_bill.code_tax"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Código postal"
                    :value="data.rs_service_insured_bill.legal_zip"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Régimen"
                    :value="
                      data.rs_service_insured_bill.fiscal_regime.fiscal_regime
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Uso CFDI"
                    :value="data.rs_service_insured_bill.fiscal_use.fiscal_use"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Forma de pago"
                    :value="
                      data.rs_service_insured_bill.fiscal_method_payment
                        .fiscal_method_payment
                    "
                  />
                </v-col>
                <v-col
                  v-if="
                    data.rs_service_insured_bill.fiscal_method_payment_id ==
                      4 ||
                    data.rs_service_insured_bill.fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Núm. de aprobación"
                    :value="data.rs_service_insured_bill.approval_number"
                  />
                </v-col>
                <v-col
                  v-if="
                    data.rs_service_insured_bill.fiscal_method_payment_id ==
                      4 ||
                    data.rs_service_insured_bill.fiscal_method_payment_id == 18
                  "
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Tarjeta (4 últ. dig.)"
                    :value="data.rs_service_insured_bill.card"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observación"
                    :value="data.rs_service_insured_bill.observation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="data.rs_service_bill" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="`DOC. ID | ${data.rs_service_bill.folio}`"
                />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-if="!data.rs_service_bill.to_stamp">
                  <v-alert type="warning" dense>
                    <div
                      class="text-center"
                      v-text="'La documentación necesita revisión'"
                    />
                    <div
                      v-if="!data.rs_service_bill.valid_receiver_code_taxs"
                      class="text-center"
                      v-text="
                        '*El RFC del receptor de una o varias facturas no es correcta'
                      "
                    />
                    <div
                      v-if="!data.rs_service_bill.valid_receiver_names"
                      class="text-center"
                      v-text="
                        '*La razón social del receptor de una o varias facturas no es correcta'
                      "
                    />
                    <div
                      v-if="!data.rs_service_bill.valid_amounts"
                      class="text-center"
                      v-text="
                        '*El monto del formato o el ingresado no concuerda con el total de una o varias facturas'
                      "
                    />
                  </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total factura'"
                  />
                  <span
                    v-text="numberFormat(data.rs_service_bill.xmls_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total IVA'"
                  />
                  <span
                    v-text="numberFormat(data.rs_service_bill.xmls_tax_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <div v-if="login.role_id">
                    <span
                      class="text-caption font-weight-bold mr-1"
                      v-text="'Total Nota de Crédito'"
                    />
                    <span
                      v-text="numberFormat(data.rs_service_bill.notes_amount)"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Total a pagar'"
                  />
                  <span
                    v-text="numberFormat(data.rs_service_bill.pay_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Cobro Aseg.'"
                  />
                  <span
                    v-text="
                      numberFormat(data.rs_service_bill.insurance_pay_amount)
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="text-caption font-weight-bold mr-1"
                    v-text="'Remesa'"
                  />
                  <span
                    v-text="
                      data.rs_service_bill.consignment
                        ? data.rs_service_bill.consignment
                        : '-'
                    "
                  />
                  <v-icon
                    v-if="
                      data.rs_service_bill.consignment &&
                      !data.rs_service_bill.consignment_verify
                    "
                    right
                    small
                    color="warning"
                  >
                    mdi-alert
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(rs_service_bill_document, i) in data.rs_service_bill
                    .rs_service_bill_documents"
                  :key="i"
                >
                  <v-row dense>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="
                          `Factura (${i + 1}) | ${
                            rs_service_bill_document.xml_folio
                          }`
                        "
                      />
                      <v-icon
                        right
                        small
                        :color="
                          rs_service_bill_document.url_bill ? 'success' : ''
                        "
                      >
                        {{
                          rs_service_bill_document.url_bill
                            ? "mdi-check"
                            : "mdi-alert"
                        }}
                      </v-icon>

                      <v-tooltip
                        bottom
                        v-if="rs_service_bill_document.url_bill"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rs_service_bill_documents/' +
                              rs_service_bill_document.url_bill
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'XML'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          rs_service_bill_document.url_bill_xml ? 'success' : ''
                        "
                      >
                        {{
                          rs_service_bill_document.url_bill_xml
                            ? "mdi-check"
                            : "mdi-alert"
                        }}
                      </v-icon>
                      <v-tooltip
                        bottom
                        v-if="rs_service_bill_document.url_bill_xml"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            :href="
                              url_documents +
                              '/rs_service_bill_documents/' +
                              rs_service_bill_document.url_bill_xml
                            "
                            target="_blank"
                          >
                            <v-icon small>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'RFC y razon social'"
                      />
                      <v-icon
                        right
                        small
                        :color="
                          rs_service_bill_document.valid_receiver_code_tax &&
                          rs_service_bill_document.valid_receiver_name
                            ? 'success'
                            : 'warning'
                        "
                      >
                        {{
                          rs_service_bill_document.valid_receiver_code_tax &&
                          rs_service_bill_document.valid_receiver_name
                            ? "mdi-check"
                            : "mdi-alert"
                        }}
                      </v-icon>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Monto total con IVA'"
                      />
                      <span
                        v-text="
                          numberFormat(rs_service_bill_document.amount_typed)
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <span
                        class="text-caption font-weight-bold mr-1"
                        v-text="'Monto XML'"
                      />
                      <span
                        v-text="
                          numberFormat(rs_service_bill_document.xml_amount)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="no_payment_dialog" scrollable persistent max-width="900">
      <v-card
        tile
        :disabled="no_payment_dialog_loading"
        :loading="no_payment_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="no_payment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="no_payment_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'MOTIVO DE NO PAGO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="no_payment_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Tipo*"
                          v-model="no_payment_data.no_payment_type_id"
                          :items="rs_service_no_payment_types"
                          item-value="id"
                          :item-text="(v) => v.no_payment_types"
                          :loading="rs_service_no_payment_types_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="error"
                          dark
                          @click.prevent="noPaymentSubmit"
                          small
                        >
                          ACEPTAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  headersToken,
  URL_API,
  URL_DOCUMENTS,
  msgAlert,
  URL_PDF,
  rules,
  msgConfirm,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      rs_service_id: parseInt(this.$route.params.rs_service_id),
      loading: true,
      data: null,
      url_documents: URL_DOCUMENTS,
      url_pdf: URL_PDF,
      page_route: "caja_medicos",
      rules: rules(),
      no_payment_data: null,
      no_payment_dialog: false,
      no_payment_dialog_loading: false,
      rs_service_no_payment_types: [],
      rs_service_no_payment_types_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      Axios.get(
        URL_API + `/rss/services/${this.rs_service_id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        if (resp.data.success) {
          this.data = resp.data.data;
          this.loading = false;
        } else {
          console.log(resp.data.message);
        }
      });
    },

    downloadFile(stamped_id, extension) {
      this.service_payment_info_dialog_loading = true;

      Axios.get(
        URL_API + `/bills/stamped/${stamped_id}/files/${extension}/2`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          const linkSource = `data:application/${extension};base64,${resp.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_bill_${stamped_id}.${extension}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(resp.data.message);
        }

        this.service_payment_info_dialog_loading = false;
      });
    },
    noPaymentDialog() {
      this.no_payment_data = {
        id: this.data.id,
        no_payment_type_id: this.data.no_payment_type_id,
      };
      this.no_payment_dialog_loading = false;
      this.no_payment_dialog = true;
    },
    noPaymentSubmit() {
      if (this.$refs.no_payment_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma actualizar el motivo de no pago?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.no_payment_dialog_loading = true;
              Axios.post(
                `${URL_API}/rss/services/no_payment`,
                this.no_payment_data,
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.no_payment_dialog = false;
                  this.getData();
                } else {
                  console.log(resp.data.message);
                }

                this.no_payment_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route =
      this.$route.name == "caja_medicos.detalle"
        ? "caja_medicos"
        : "pend_cobros";

    Axios.get(
      `${URL_API}/catalog?name=rs_service_no_payment_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.rs_service_no_payment_types = resp.data.data;
      this.rs_service_no_payment_types_loading = false;
    });

    this.getData();
  },
};
</script>